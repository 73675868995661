.our-mission {
    padding-top: 60px;
    @include mq(medium){
        padding-top: 70px;
    }
    &__text{
        text-align: center;
        font-size: $small;
        font-weight: $semi-bold;
        @include mq(medium){
            width: 40%;
            margin: auto;
        }

        &--secondary{
            margin-top: 0;
        }
    }

    &__heading{
        text-align: left;
    }

    &__flex {
        display:flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-top: 1rem;
    }

    &__image-wrap{
        flex: 1 0 300px;
        align-self: center;
        height: 300px;
    }
    
    &__description{
        flex: 1 0 300px;
        align-self: center;
        margin: 20px;
    }    

    &__description {
        align-self: center;
        .primary-text {
            margin-bottom: 25px;        
        }    
        margin-bottom: 25px;
    }

    &__image {
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }
    
}


.about {
    background-color: $primary-background;
    padding: 0 0 0 0;
    @include mq(medium){
        padding: 0 0 0 0;
    }

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }


    &__details{
        border: 1px solid $card-border;
        border-radius: 20px;
        padding: 20px 15px;
        margin-top: 40px;
        @include mq(medium){
            padding: 20px;
        }
    }

    &__text{
        font-weight: $semi-bold;
        line-height: 28px;
    }

    &__author{
        background-color: $testimonial-background;
        border-radius: 20px;
        padding: 20px 15px;
        margin-top: 30px;
    }

    &__author-text{
        margin: 0;
        font-weight: $semi-bold;
        line-height: 28px;
        color: $black;
    }

    &__author-name{
        font-weight: $semi-bold;
        line-height: 28px;
        color: $green;
    }

    &__author-image-wrap{
        width: 120px;
        height: 120px;
        border-radius: 10px;
    }

    &__author-image{
        width: 100%;
    }
}


.image-gallery {
    &__items{
        margin: 20px;
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__item{
        margin: 30px 0;
        @include mq(medium){
            flex: 0 0 19%;
            margin: 0;
        }
    }

    &__wrap{
        max-width: 230px;
        height: 180px;
        border-radius: 16px;
        margin: 30px auto;
    }

    &__image{
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
    }

    &__text{
        text-align: center;
        display: block;
        margin: 20px 0;
    }

    &__text-break{
        display: block;
    }
}
