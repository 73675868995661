.terms{
    padding: 4rem 0 2rem 0;
    @include mq(medium){
        padding: 8rem 0 2rem 0;
    }

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;

        p{
            margin-bottom: 30px;
        }

        a{
            margin-bottom: 20px;
            display: block;
        }
    }

    &__heading{
        text-align: left;
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.sub-heading{
    font-weight: $bold;
    font-size: $extra-small;
    line-height: 30px;
    text-transform: uppercase;
    color: $secondary-text-dark;
}

.version{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;

        p{
            margin-bottom: 30px;
        }
    }

    &__emphasis{
        font-weight: $secondary-semi-bold;
        color: $green;
        margin: 10px 0;
        display: block;

        &:hover{
            text-decoration: none;
        }

        &--margin{
            margin-bottom: 30px;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.interpretation{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__text{
        &--margin{
            margin: 30px 0;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.delivery{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__sub-heading{
        margin: 20px 0 0 0;
    }

    &__text{
        p{
            margin: 0;
        }

        &--margin{
            margin: 30px 0;
        }

        &--fast-margin{
            margin-top: 15px;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.supplier{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__sub-heading{
        margin: 20px 0 0 0;
    }

    &__text{
        p{
            margin: 0;
        }

        &--margin{
            margin: 30px 0;
        }

        &--fast-margin{
            margin-top: 15px;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.licences{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__sub-heading{
        margin: 20px 0 0 0;
    }

    &__text{
        p{
            margin: 0;
        }

        &--margin{
            margin: 30px 0;
        }

        &--fast-margin{
            margin-top: 15px;
        }
    }
}

.payment{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__sub-heading{
        margin: 20px 0 0 0;
    }

    &__text{
        p{
            margin: 0;
        }

        &--margin{
            margin: 30px 0;
        }

        &--fast-margin{
            margin-top: 15px;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.security{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__sub-heading{
        margin: 20px 0 0 0;
    }

    &__text{
        p{
            margin: 0;
        }

        &--margin{
            margin: 30px 0;
        }

        &--fast-margin{
            margin-top: 15px;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.warranties{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__sub-heading{
        margin: 20px 0 0 0;
    }

    &__text{
        p{
            margin: 0;
        }

        &--margin{
            margin: 30px 0;
        }

        &--fast-margin{
            margin-top: 15px;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.confidentiality{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__sub-heading{
        margin: 20px 0 0 0;
    }

    &__text{
        p{
            margin: 0;
        }

        &--margin{
            margin: 30px 0;
        }

        &--fast-margin{
            margin-top: 15px;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.communications{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__sub-heading{
        margin: 20px 0 0 0;
    }

    &__text{
        p{
            margin: 0;
        }

        &--margin{
            margin: 30px 0;
        }

        &--fast-margin{
            margin-top: 15px;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.dispute{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__sub-heading{
        margin: 20px 0 0 0;
    }

    &__text{
        p{
            margin: 0;
        }

        &--margin{
            margin: 30px 0;
        }

        &--fast-margin{
            margin-top: 15px;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.termination{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__sub-heading{
        margin: 20px 0 0 0;
    }

    &__text{
        p{
            margin: 0;
        }

        &--margin{
            margin: 30px 0;
        }

        &--fast-margin{
            margin-top: 15px;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.general{

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__sub-heading{
        margin: 20px 0 0 0;
    }

    &__text{
        p{
            margin: 0;
        }

        &--margin{
            margin: 30px 0;
        }

        &--fast-margin{
            margin-top: 15px;
        }

        &--less-margin{
            margin-top: -30px;
        }
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.schedule{
    margin-top: 50px;
    margin-bottom: 50px;
    @include mq(medium){
        margin-bottom: 200px;
    }

    &__content{
        max-width: 940px;
        width: 100%;
        margin: auto;
    }

    &__heading{
        font-weight: $bold;
        font-size: $medium-nav;
        line-height: 24px;
        color: $secondary-text-dark;
    }
    a{
        color: $blue;
        
        &:hover{
            text-decoration: none;
        }
    }

}