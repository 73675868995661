// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer{
    padding: 2rem 0 1rem 0;
    @include mq(medium){
        padding: 2rem 0 1rem 0;
    }
    
    &__logo-icon{
        @include mq(medium){
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__logo{
        width: 240px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 10px;
        @include mq(medium){
            margin: inherit;
            padding-left: 0;
        }
    }

    &__logo-image{
        display: block;
        margin-left: -15px;
    }

    &__logo-img{
        width: 100%;
        
    }
    &__logo-name{
        @include hidden-text;
    }
    &__icon-name{
        @include hidden-text;
    }

    &__social-icons{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        gap: 50px;
        @include mq(medium){
            gap: 30px;
            margin: 0 10px 0 0;
        }
    }

    &__content{
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 30px;
        }
    }

    &__nav-items{
        flex: 0 0 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
    }

    &__nav-items-wrap{
        flex: 0 0 50%;
    }

    &__nav-items-main{
        @include mq(medium){
            display: flex;
            justify-content: flex-start;
            gap: 20px;
        }
        @include mq(large){
            gap: 40px;
        }
    }

    &__nav-item{
        display: block;
        margin: 10px 0 20px 0;
        line-height: 17px;
        @include mq(small){
            padding-right: 5px;
            line-height: 22px;
        }

        &:hover{
            text-decoration: none;
        }

        &--block{
            flex: 0 0 100%;
        }

    }

    &__nav-item--mobile{
        @include mq(medium){
            display: none;
        }
    }
    &__nav-item--desktop{
        display: none;
        @include mq(medium){
            display: block;
        }
    }

    &__address{
        flex: 0 0 28%;
        margin-top: 50px;
        @include mq(medium){
            display: flex;
            justify-content: flex-end;
            margin-top: 0;
            margin-right: 10px;
        }

        strong{
            display: block;
            font-weight: $normal;
        }
    }

    &__address-text{
        width: 55%;
        margin: 5px 0;
        font-style: normal;
        line-height: 22px;
        display: block;
        @include mq(medium){
            width: 100%;
        }
    }

    &__address-wrap{
        @include mq(medium){
            flex: 0 0 85%;
        }
    }

    &__contact{
        margin-top: 12px;
        width: 100%;
        @include mq(medium){
            flex: 0 0 23%;
            margin-right: -15px;
        }

        a{
            font-style: normal;
            font-size: $extra-small;
            line-height: 24px;
            color: $secondary-text-dark;
        }
    }

    &__hr{
        border: none;
        height: 1px;
        background-color: $secondary-border;
        margin: 30px 0 20px 0;
    }

    &__bottom{
        text-align: center;
    }

    &__copyright-text{
        line-height: 17px;
    }

    &__bottom-page{
        padding-right: 5px;
        line-height: 22px;
        
        &:hover{
            text-decoration: none;
        }
    }
}