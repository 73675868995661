// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header{
    background-color: $primary-background;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

    &__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        @include mq(medium){
            height: auto;
        }
    }

    &__nav-mobile{
        display: flex;
        justify-content: flex-start;
        
    }

    &__nav-desktop{
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex: 0 0 550px;
        }
        @include mq(large){
            flex: 0 0 600px;
        }
    }

    &__nav-items{
        display: none;
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
        }
        @include mq(large){
            gap: 100px;
        }

    }

    &__nav-item{
        @include mq(medium){
            font-weight: $semi-bold;
            font-size: $extra-small;
            line-height: 17px;
            color: $primary-text-dark;
            font-size: 1rem;

            &:hover{
                text-decoration: none;
            }
        }

        &--active{
            color: $green;
        }
    }

    &__logo {
        img {
            width: 140px;
            height: 70px;    
        }
    }


    &__logo-name{
        @include hidden-text;
    }

    &__nav-mobile-icon{
        @include mq(medium){
            display: none;
        }
        &:hover{
            cursor: pointer;
        }
    }

    //nav-design start

    &__nav-mobile-main{
        justify-content: space-between;

        &--active{
            display: flex;
        }
    }

    &__nav-mobile-image-wrap{
        flex: 0 0 30%;
        position: absolute;
    }

    &__nav-mobile-image{
        width: 100%;
    }

    &__nav-mobile-menu{
        width: 100%;
        background-color: $mobile-nav-background;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        flex: 0 0 70%;
        z-index: -1000;
        transition: transform 0s ease-in-out;
        @include mq(medium){
            display: none;
        }

    }

    &__nav-mobile-items{
        margin-top: 40px;
        border-bottom: 1px solid $nav-border;
    }

    &__nav-mobile-item{
        display: block;
        padding: 10px 20px;
        font-weight: $bold;
        line-height: 29px;
        font-size: $medium-nav;
        color: $secondary-text-dark;
        letter-spacing: -0.3px;
        width: 60%;
        @include mq(medium){
            width: auto;
        }

        &:hover{
            text-decoration: none;
        }
    }

    &__sub-nav-mobile{
        margin-top: 5px;
    }

    &__sub-nav-mobile-item{
        display: block;
        padding: 5px 20px;
        font-weight: $semi-bold;
        font-size: $extra-small;
        line-height: 17px;
        color: $nav-sub-color;
        letter-spacing: 0.3px;

        &:hover{
            text-decoration: none;
        }
    }

    &__nav-mobile-social{
        position: absolute;
        bottom: 50px;
    }

    &__nav-mobile-icons{
        padding: 10px 20px 15px 20px;
        border-bottom: 1px solid $nav-border;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    &__nav-mobile-icon{
        margin-right: 20px;
    }
    &__nav-mobile-icon-name{
        @include hidden-text;
    }

    &__logo-mobile-nav{
        width: 117px;
        display: block;

        img{
            width: 100%;
        }
    }
}
.content-wrapper{
    background-color: #fff;
    width: 100%;
}
.content-wrapper.transition{
    transition: transform .7s ease-in-out;
}
.menu--open{
    transform: translate(65%,40px);
    position: fixed;
    filter: blur(1.5px);
    transition: transform .7s ease-in-out,
                filter 1s ease-in-out;
}