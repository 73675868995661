.trial{
    &__form{
        margin-top: 20px;
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 40px;
        }
    }

    &__input{
        display: block;
        width: 100%;
        margin-bottom: 10px;
        border: 1px solid $nav-border;
        font-size: $extra-small;
        line-height: 17px;
        color: $secondary-text-dark;
        padding: 12px 30px;
        border-radius: 30px;
        @include mq(medium){
            flex: 0 0 49%;
        }
    }

    &__form-sub-heading{
        font-weight: $bold;
        font-size: $small;
        line-height: 26px;
        color: $primary-text-dark;
        flex: 0 0 100%;
    }

    &__check-items{
        padding: 0;
        margin: -10px 0 0 0;
        list-style: none;
    }

    &__check-item{
        font-size: $extra-small;
        line-height: 30px;
        color: $secondary-text-dark;

        label{
            margin-left: 10px;
        }
    }

    &__text-wrap{
        border: 1px solid $nav-border;
        border-radius: 16px;
        margin-top: 30px;
        padding: 10px 20px;
    }

    &__button{
        display: table;
        margin: 20px auto;
        padding: 12px 40px;
        @include mq(medium){
            margin: 30px auto;
        }
    }
}