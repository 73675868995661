.article{

    &__social-icons{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    &__social-icon{
        &--text{
            margin-right: 15px;
        }
    }
}