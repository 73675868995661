.contact-us{
    padding: 4rem 0 0 0;
    @include mq(medium){
        padding: 6rem 0 2rem 0;
    }

    &__content{
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__form-wrap{
        @include mq(medium){
            flex: 0 0 59%;
        }
    }

    &__heading{
        text-align: left;
    }

    &__button{
        padding: 12px 58px;
        @include mq(medium){
            margin: 30px 0;
        }
    }

    .zcwf_title {
        display:none;
    }

    label {
        font-family: $primary-font;
    }

    &__address-wrap{
        margin-top: 30px;
        @include mq(medium){
            flex: 0 0 50%;
        }
    }

    &__address-card{
        background-color: $testimonial-background;
        border: 1px solid $nav-border;
        border-radius: 20px;
        padding: 30px 40px;
        margin-bottom: 20px;

        p{
            color: $secondary-text-dark;
            font-style: normal;
            font-size: $extra-small;
            line-height: 22px;
            margin: 0;
        }
    }

    &__address-icon-radius{
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: $primary-background;
        border: 1px solid $icon-border;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__address-heading{
        font-style: normal;
        font-weight: $bold;
        font-size: $extra-small;
        line-height: 22px;
        color: $primary-text-dark;

        &--margin{
            margin-bottom: 30px;
        }
    }

    &__address-text{
        font-style: normal;
        font-size: $extra-small;
        line-height: 22px;
        margin: 5px 0;

        strong{
            font-weight: $normal;
            display: block;
        }
    }
}

#firstname-9dc7a75d-3884-42ed-9ed6-6a79da0fd3ed{
    border-bottom: none !important;
}

#hs-form-iframe-0{
    form{
        label{
            color: red;
        }
    }
}