.ar-hero{
    height: 520px;
    background-image: url(/images/ar-hero.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 6rem 0 4rem 0;
    display: grid;
    place-items: center;
    position: relative;
    @include mq(medium){
        padding: 12rem 0 8rem 0;
        height: 100vh;
    }

    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background: $hero-background;
    }

    &__content{
        position: relative;
        max-width: 600px;
        width: 100%;
        margin: auto;
    }

    &__heading{
        font-size: $extra-large-mobile;
        line-height: 56px;
        text-align: center;
        color: $primary-text-white;
        @include mq(small){
            font-size: $extra-large-mobile-secondary;
        }
        @include mq(medium){
            font-size: $extra-large;
            line-height: 61px;
        }
    }

    &__text{
        font-weight: $semi-bold;
        font-size: $medium-small;
        line-height: 26px;
        color: $primary-text-white;
        text-align: center;
        margin-top: -10px;
        @include mq(medium){
            font-size: $medium;
            width: 90%;
            margin: -10px auto 0 auto;
        }
    }
}

.facemask{

    &__content{
        padding: 30px 0;
    }

    &__text{
        font-weight: $normal;
        font-size: $small-secondary;
        line-height: 35px;
        color: $primary-text-dark;
    }
}

.focus{
    @include mq(medium){
        padding-top: 30px;
    }

    &__heading{
        text-align: left;
        font-size: $medium-secondary;
    }

    &__content{
        margin-top: 30px;
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__text-wrap{
        flex: 0 0 48%;
    }

    &__image-wrap{
        flex: 0 0 48%;
        height: 193px;
        @include mq(medium){
            height: 421px;
        }
    }

    &__sub-heading{
        font-weight: $bold;
        font-size: $medium;
        line-height: 20px;
        text-transform: capitalize;
        color: $secondary-text-dark;
        margin-top: 0;
    }

    &__text{
        font-size: $small;
        @include mq(medium){
            width: 90%;
        }
    }

    &__image{
        width: 100%;
        height: 100%;
        border-radius: 16px;
        @include mq(medium){
            border-radius: 20px;
        }
    }
}

.trial__text {
    text-align:center;
}