.blog-main{
    padding: 4rem 0 2rem 0;
    @include mq(medium){
        padding: 6rem 0 2rem 0;
    }

    &__content{
        @include mq(medium){
            max-width: 940px;
            width: 100%;
            margin: auto;
        }
    }

    &__heading{
        text-align: left;
        margin-bottom: 0;
    }

    &__text{
        line-height: 24px;
        margin-top: 10px;
    }

    &__article-image-wrap{
        width: 100%;
        height: 190px;
        border-radius: 16px;
        @include mq(medium){
            height: 320px;
            border-radius: 20px;
        }
    }

    &__article-image{
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
        @include mq(medium){
            border-radius: 20px;
        }
    }

    &__article-headline{
        @include mq(medium){
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    &__article-heading{
        font-weight: $semi-bold;
        line-height: 30px;
        text-transform: uppercase;
        @include mq(medium){
            flex: 0 0 75%;
        }

        a{
            text-decoration: none;
            color: $primary-text-dark;
            &:hover{
                text-decoration: underline;
            }
        }
    }

    &__article-text{
        font-weight: $secondary-semi-bold;
        line-height: 30px;
        @include mq(medium){
            flex: 0 0 10%;
        }
    }

    &__article-cards{
        margin-top: 40px;
        padding: 0 20px;
        @include mq(medium){
            margin-top: 50px;
            padding: 0;
        }
    }

    &__article-card{
        margin-top: 30px;
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 30px;
            margin-top: 50px;
        }
    }

    &__image-wrap{
        width: 100%;
        height: 180px;
        border-radius: 16px;
        @include mq(medium){
            flex: 0 0 32%;
        }
    }

    &__image{
        width: 100%;
        height: 100%;
        display: block;
        margin: auto;
        border-radius: 16px;
        object-fit: cover;
    }

    &__card-headline{
        @include mq(medium){
            flex: 0 0 64%;
        }
    }

    &__card-heading{
        font-weight: $semi-bold;
        font-size: $small;
        line-height: 30px;
        text-transform: uppercase;
        @include mq(medium){
            font-size: $medium;
        }

        a{
            text-decoration: none;
            color: $primary-text-dark;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}