// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$primary-font: 'Montserrat';


/// font sizes for different width screen
$extra-large: 50px;
$extra-large-mobile: 40px;
$extra-large-mobile-secondary: 36px;
$large: 30px;
$large-mobile: 32px;
$medium: 20px;
$medium-nav: 24px;
$medium-secondary: 26px;
$small-secondary: 18px;
$small: 16px;
$medium-small: 15px;
$extra-small: 14px;


/// font weight for the website
// $normal-weight
$normal: normal;
$semi-bold: 600;
$secondary-semi-bold: 500;
$bold: bold;


/// color scheme for the website
/// @type Color
$primary-text-dark: #333333;
$secondary-text-dark: #4F4F4F;
$primary-text-white: #FFFFFF;
$primary-text: #03C4C5;
$green: #02C4C4;
$aqua: #02C4C4;
$grey: #4f4f4f;
$black: #000000;
$nav-sub-color: #BDBDBD;
$blue: #2D9CDB;
$orange: #ee7d4b;
$purple: #8078b7;
$light-purple: #b3afce;
$secondary-green: #a6d66e;
$light-aqua: #99e8e8;
$pink: #e75468;

//background-colors
$primary-background: #FFFFFF;
$secondary-background: #F7F7F7;
$privacy-background: #F8F8F8;
$secondary-background-light: rgba(247, 247, 247, 0.5);
$secondary-background-article: #FAFAFA;
$secondary-background-article-light: rgba(250, 250, 250, 0.5);
$mobile-nav-background: rgba(229, 229, 229, 1);
$testimonial-background: #F3F3F3;
$ar-hero-background: rgba(86, 86, 86, 0.6);
$hero-background: rgba(0, 0, 0, 0.25);
$ecoshot-hero-layer-background: linear-gradient(rgba(255, 255, 255, 1) 1% , transparent 99%);

//border-colors
$primary-border: #BFBFBF;
$primary-border-light: rgba(191, 191, 191, 0.5);
$secondary-border: #EAEAEA;
$green-border: #02C4C4;
$nav-border: #E0E0E0;
$case-border: #CBCBCB;
$icon-border: #C4C4C4;
$card-border: #DDDDDD;
$article-border: #ECECEC;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
  'xlarge': 1400px,
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
