.privacy-data{
    padding: 4rem 0 2rem 0;
    @include mq(medium){
        padding: 8rem 0 2rem 0;
    }

    &__content{
        @include mq(medium){
            max-width: 940px;
            width: 100%;
            margin: auto;
        }
    }

    &__heading{
        text-align: left;
    }

    &__text{

        &--color{
            color: $black;
        }

        &--padding{
            padding: 0 30px;
            width: 81%;
        }

        &--break{
            width: 70%;
        }

        &--margin{
            margin-top: 30px;
        }

        &--margin-secondary{
            margin: 3px 0;
        }

        &--padding-secondary{
            padding: 0 20px 0 30px;
        }

        &--bold{
            font-weight: $semi-bold;
        }

        &--desktop{
            display: none;
            @include mq(medium){
                display: block;
            }
        }
        a{
            color: currentColor;
        }
    }

    &__collect-info{
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__collect-list{
        @include mq(medium){
            flex: 0 0 33.33%;
        }
    }

    &__list-items{
        padding-left: 50px;
        margin-top: 0;
        list-style: disc;

        &--decimal{
            list-style: decimal;
        }

        &--padding-secondary{
            padding-left: 25px;
            width: 100%;
        }

        &--secondary{
            padding-left: 34px;
        }

        &--margin{
            margin: 20px 0;
            padding-left: 25px;
            width: 100%;
        }
    }

    &__list-item{
        width: 70%;

        &--secondary{
            width: 100%;
        }
    }

    &__collect-info{
        background-color: $privacy-background;
        border: 1px solid $nav-border;
        border-radius: 16px;
        padding: 10px 10px 20px 10px;
        @include mq(medium){
            padding: 20px;
        }
    }

    &__collect-heading{
        background-color: $primary-background !important;
        border-radius: 16px;
        padding: 30px;
        margin-top: 0;
        font-weight: $semi-bold;
        font-size: $extra-small;
        line-height: 24px;
        @include mq(medium){
            height: 120px;
            display: flex;
            align-items: center;
            border-radius: 0;
        }

        &--left-border{
            @include mq(medium){
                border-radius: 16px 0 0 16px;
            }
        }

        &--right-border{
            @include mq(medium){
                border-radius: 0 16px 16px 0;
            }
        }
    }

    &__info-size{
        @include mq(medium){
            height: 230px;
        }
        @include mq(large){
            height: 170px;
        }

        &--big{
            @include mq(medium){
                height: 395px;
            }
            @include mq(large){
                height: 270px;
            }
        }

        &--small{
            @include mq(medium){
                height: 290px;
            }
            @include mq(large){
                height: 185px;
            }
        }

        &--big-secondary{
            @include mq(medium){
                height: 610px;
            }
            @include mq(large){
                height: 415px;
            }
        }

        &--small-secondary{
            @include mq(medium){
                height: 240px;
            }
            @include mq(large){
                height: 220px;
            }
        }
    }

    &__hr{
        border: none;
        height: 1px;
        background-color: $nav-border;
        margin: 20px 0;
    }

    &__address{
        display: none;
        @include mq(medium){
            display: block;
            max-width: 270px;
            width: 100%;
            background-color: $testimonial-background;
            border-radius: 20px;
            padding: 20px 25px;
            margin-top: 30px;
        }
    }

    &__address-text{
        font-weight: $semi-bold;
        font-size: $extra-small;
        line-height: 24px;
        color: $black;
        font-style: normal;
        margin: 0;
    }
}