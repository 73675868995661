.featured-page {
    margin-top: 30px;
    @include mq(medium){
        margin-top: 70px;
    }

    &__content{
        padding: 30px 30px 40px 30px;
        background-color: $testimonial-background;
        border-radius: 20px;
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 40px;
        }
    }

    &__image-wrap{
        @include mq(medium){
            flex: 0 0 38%;
        }
    }

    &__text-wrap{
        @include mq(medium){
            flex: 0 0 58%;
        }
    }

    &__image{
        width: 100%;
        height: 100%;
        border-radius: 4px;
        @include mq(medium){
            border-radius: 20px;
            object-fit: cover;
        }
    }

    &__heading{
        font-weight: $bold;
        font-size: $small;
        line-height: 24px;
        text-transform: capitalize;
        color: $secondary-text-dark;
        margin-top: 30px;
        @include mq(medium){
            font-size: $medium;
            margin-top: 0;
        }
    }

    &__text{
        font-size: $small;
        line-height: 28px;
    }

    &__button{
        margin-top: 40px;
        display: table;
    }
}