.property{
    padding: 4rem 0 0 0;
    @include mq(medium){
        padding: 6rem 0 2rem 0;
    }

    &__content{
        @include mq(medium){
            max-width: 940px;
            width: 100%;
            margin: auto;
        }
    }

    &__heading{
        text-align: left;
        margin-bottom: 0;
    }

    &__text{
        line-height: 24px;
        margin-top: 5px;
    }

    &__cards{
        margin-top: 40px;
    }

    &__card{
        border: 1px solid $card-border;
        border-radius: 20px;
        margin-bottom: 20px;
        padding: 10px 20px;
    }

    &__date-text{
        line-height: 24px;
    }

    &__card-text{
        line-height: 28px;
    }
}