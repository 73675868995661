@charset "UTF-8";

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// 2. Vendors
@import
  'vendors/normalize';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

// 4. Layout-related sections
@import
  'layout/header',
  'layout/footer',
  'layout/form',
  'layout/blog-card',
  'layout/article-social-icon';

// 5. Components
@import
  'components/button',
  'components/dot',
  'components/featured-page';







$heroDeskFallback: 'https://metail.com/images/hero-desktop_hu10434851310255391087.png';
$heroDeskLoRes: 'https://metail.com/images/hero-desktop_hu6529166137416180919.webp';
$heroDeskHiRes: 'https://metail.com/images/hero-desktop_hu6215254278906615741.webp'; 






$heroMobileFallback: 'https://metail.com/images/hero-desktop_hu10434851310255391087.png';
$heroMobileLoRes: 'https://metail.com/images/hero-desktop_hu6529166137416180919.webp';
$heroMobileHiRes: 'https://metail.com/images/hero-desktop_hu6215254278906615741.webp';  



$esHeroMobile: 'https://metail.com/images/products/ecoshotHeroPortrait_hu16210284606611236170.png';



$esHeroTablet: 'https://metail.com/images/products/ecoshotHeroSquare_hu18243735074562632563.png';



$esHeroDesktop: 'https://metail.com/images/products/ecoshotHeroLandscape_hu1977854503025882767.png';

// 6. Page-specific styles
@import
  'pages/home',
  'pages/eco-shot',
  'pages/projects',
  'pages/privacy-and-data',
  'pages/blog',
  'pages/article',
  'pages/single',
  'pages/contact',
  'pages/intellectual-property',
  'pages/terms-and-condition',
  'pages/about-us',
  'pages/augmented-reality',  
  'pages/leadpage';

// 7. Themes
@import
  'themes/default';
