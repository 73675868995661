.blog {

    &__cards {
        @include mq(medium) {
            margin-top: 40px;
        }
    }
    
    &__card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 30px;
    }
    
    &__image-wrap {
        height: 180px;
        flex: 0 1 300px;
        max-width: 450px;
    }
    
    &__headline {
        flex: 1 1;
        margin-left: 20px;
        @include mq(medium) {
            margin-left: 40px;
        }
        min-width: 300px;
    }
    
    &__image {
        width:100%;
        height:100%;
        object-fit: cover;
        border-radius: 16px;
    }
    
    &__heading{
        font-weight: $semi-bold;
        font-size: $small;
        line-height: 30px;
        text-transform: uppercase;
        color: $primary-text-dark;
        @include mq(medium){
            font-size: $medium;
        }
    }
    
    &__date{
        font-weight: $secondary-semi-bold;
        font-size: $extra-small;
        line-height: 30px;
        text-transform: uppercase;
        color: $secondary-text-dark;
        margin-bottom: 20px;
    }
}

