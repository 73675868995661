// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

html {
    scroll-behavior: smooth;
}
.hero {
    margin-top: 0;
    margin-bottom: 0;
    
    @include mq(medium) {
        padding: 30px 20px 0;

    }

    &__banner {
        display: flex;
        font-size: 0.75rem;
        line-height: 1.4;
        font-weight: 600;
        text-align: center;
        gap: 0.5rem;
        padding: 1.25rem 1rem;
        align-items: center;
        justify-content: space-between;
        background: rgba(0, 201, 201, 0.90);
        img {
            width: 100%;
            height: auto;
            max-width: 8rem;
        }
        strong {
            font-weight: 700;
        }
        &_content {

        }
        a {
            color: inherit;
            text-decoration: underline;
        }
        @include mq(medium) {
            border-radius: 50px;
            gap: 2rem;
            padding: 1.5rem 3rem;
            font-size: 1rem;
        }
        @include mq(large) {
            font-size: 1.25rem;
            padding: 1.5rem 6rem;
            img {
                max-width: 10rem;
            }
        }
        @include mq(xlarge) {
            font-size: 1.875rem;
            padding: 1.5rem 8rem;
            img {
                max-width: 14rem;
            }
        }
    }

    &__copy {
        padding: 1rem 2rem 0;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
        @include mq(medium) {
            display: none;
        }
    }

    &__content {
        color: white;
        min-height: 424px;
        background-image: url($heroMobileFallback);
        background-image: -webkit-image-set(url($heroMobileLoRes) 1x, url($heroMobileHiRes) 2x);
        background-image: image-set($heroMobileLoRes 1x, $heroMobileHiRes 2x);        
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position:relative;
        display: flex;
        flex-direction: column;
        margin: 60px -20px 0;
        // Medium breakpoint didn't quite work for layout
        @include mq(medium) {
            margin: 74px 0 0;
            min-height: 500px;
            justify-content: space-between;
            border-radius: 50px;
            justify-content: left;
            background-image: url($heroDeskFallback);
            background-image: -webkit-image-set(url($heroDeskLoRes) 1x, url($heroDeskHiRes) 2x);
            background-image: image-set($heroDeskLoRes 1x, $heroDeskHiRes 2x);
            background-position: center;
        }
        @include mq(large) {
            min-height: 686px;
        }
    }

    &__heading {
        position: relative;
        font-size: 2.5rem;
        line-height: 30px;
        font-weight: 600;
        margin-top: auto;
        margin-bottom: 0;
        margin-left: 1rem;
        @include mq(small) {
            line-height: 40px;
        }
        @include mq(medium) {
            font-size: 4rem;
            line-height: 1.2;
            margin-left: 3rem;
        }
        @include mq(large) {
            font-size: 5.625rem;
        }
    }

    &__subHeading {
        position:relative;
        font-size: $small;
        line-height: 28px;
        margin: 0.23em 0em;
        text-align: right;
        margin-top: 1rem;
        margin-right: 1rem;
        @include mq(medium) {
            font-size: $medium;
            line-height: 35px;
            margin-right: 2rem;
            
        }
        @include mq(large) {
            margin-right: 8rem;
            font-size: 2.0625rem;
        }
    }


}

.intro {
    margin-top: 4rem;
    @include mq(medium) {
        margin-top: 6rem;
    }
    &__grid {
        display: grid;
        align-items: center;
        @include mq(medium) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    &__img {
        
        @include mq(medium) {
            border-right: solid 2px #CDCDCD;
            padding: 7.3125rem 3.125rem 7.3125rem 0;
        }
    }

    hr {
        border-top: solid 2px #CDCDCD;
        margin: 3.375rem 2.5rem 2rem;
        @include mq(medium) {
            display: none;
        }
    }

    &__copy {
        font-size: 1.125rem;
        line-height: 1.25;
        font-weight: 500;
        text-align: center;
        @include mq(medium) {
            padding-left: 3rem;
        }
        @include mq(large) {
            font-size: 1.375rem;
        }
        strong {
            color: $primary-text;
            font-weight: 500;
        }
    }

    .desktop {
        display: none;
        @include mq(medium) {
            display: block;
        }
    }

    .mobile {
        margin-top: 2rem;
        @include mq(medium) {
            display: none;
        }
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 50px;
    }
    .image-container {
        position: relative;
    }
    .rotating-image:not(.first) {
        position: absolute;
    }
    .rotating-image {
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }
    .visible {
        opacity: 1;
    }
}

.coming {
    margin-top: 4rem;
    @include mq(medium) {
        margin-top: 6rem;
    }

    .button {
        margin-top: 0.5rem;
        display: inline-block;
        font-size: 1.25rem;
        padding: 20px 56px;

        @include mq(large) {
            font-size: 1.875rem;
            padding: 30px 50px;
        }
    }

    &__grid {
        display: grid;
        align-items: center;
        @include mq(medium) {
            gap: 2rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include mq(large) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 6rem;
        }
    }

    &__img {
        text-align: center;
        @include mq(medium) {
            // padding: 7.3125rem 3.125rem 7.3125rem 0;
            order: 9999;
        }

        .button {
            display: none;
            margin-top: 1rem;
            @include mq(medium) {
                display: inline-block;
            }
        }
    }

    &__img_2 {
        margin: 0 1.5rem;
        @include mq(medium) {
            margin: 0 6rem;
        }
    }

    hr {
        border-top: solid 2px #CDCDCD;
        margin: 2.75rem 3rem 2.5rem;
        @include mq(medium) {
            display: none;
        }
    }

    &__copy {
        font-size: 1.25rem;
        line-height: 1.5;
        font-weight: 500;
        text-align: center;
        @include mq(medium) {
            text-align: left;
        }
        strong {
            color: $primary-text;
            font-weight: 500;
        }
        @include mq(large) {
            grid-column: span 2/span 4;
            
        }
        .button {
            @include mq(medium) {
                display: none;
            }
        }
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 1rem;
    }

}

.expertize {
    margin-top: 4rem;
    @include mq(medium) {
        margin-top: 6rem;
    }
    &__grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mq(medium) {
            flex-direction: row;
        }
    }

    &__img {
        
        @include mq(medium) {
            order: -1;
            flex: 1 1 0%;
            border-right: solid 2px #CDCDCD;
            padding: 3.5rem 1.5rem 3.5rem 0;
        }
    }

    hr {
        border-top: solid 2px #CDCDCD;
        margin: 3.375rem 0 1rem;
        @include mq(medium) {
            display: none;
        }
    }

    &__copy {
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 600;
        width: 100%;
        @include mq(medium) {
            width: 42%;
            padding-left: 2rem;
        }
        @include mq(large) {
            width: 42%;
            padding-left: 3rem;
        }
        strong {
            color: $primary-text;
            font-weight: 500;
        }
        a {
            color: $primary-text;
            text-decoration: underline;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    &__heading {
        color: $primary-text;
        font-size: 1.875rem;
        line-height: 1.2;
        margin-bottom: 0;
        margin-top: 0;
        @include mq(large) {
            font-size: 2.75rem;
            line-height: 1.1;
        }
    }

    &__lead {
        color: $primary-text;
        text-align: center;
        margin-top: 0;
        @include mq(medium) {
            text-align: left;
        }
        @include mq(large) {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4;
        }
    }

    &__copy_1 {
        text-align: right;
        margin-bottom: 0;
        @include mq(medium) {
            text-align: left;
        }
        @include mq(large) {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4;
        }
        span {
            color: $primary-text;
        }
    }

    &__copy_2_mobile {
        font-size: 0.9375rem;
        line-height: 1.6;
        text-align: center;
        font-weight: 600;
        @include mq(medium) {
            display: none;
        }
    }

    &__copy_2_desktop {
        display: none;
        @include mq(medium) {
            display: block;
        }
        @include mq(large) {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4;
        }
    }

    blockquote {
        margin: 0.5rem 0;
        padding: 2.5rem 0;
        position: relative;
        color: #A6A6A6;
        text-align: center;
        font-size: 12.5px;
        font-style: italic;
        font-weight: 600;
        line-height: 1.9;
        span {
            position: relative;
            max-width: 20rem;
            margin: 0 auto;
            display: block;
        }
        @include mq(medium) {
            line-height: 1.7;
        }
        @include mq(large) {
            font-size: 1.125rem;
            line-height: 1.7;
            margin: 2rem 0;
            span {
                max-width: 30rem;
            }
        }
    }

    .top {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .bottom {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.cta {
    margin-top: 4rem;
    position: relative;

    @include mq(medium) {
        margin-top: 6rem;
    }

    .button {
        margin-top: 0.5rem;
        display: inline-block;
        font-size: 1.25rem;
        padding: 20px 56px;
        @include mq(large) {
            margin-top: 2rem;
        }
        @include mq(xlarge) {
            font-size: 1.875rem;
            padding: 30px 50px;
            margin-top: 2rem;
        }
    }

    &__img {
        overflow: auto;
        p {
            display: none;
            @include mq(large) {
                display: block;
            }
        }
        .button {
            display: none;
            @include mq(large) {
                display: inline-block;
            }
        }

    }

    .cta__copy {
        strong {
            font-weight: 600;
            color: $primary-text;
        }
        font-weight: 600;
        font-size: 0.9375rem;
        line-height: 1.7;
        @include mq(medium) {
            font-size: 1.375rem;
        }
        @include mq(large) {
            font-size: 1.5rem;
        }
        @include mq(xlarge) {
            font-size: 1.5rem;
        }


    }

    .cta__copy_mobile_2 {
        color: white;
    }

    .cta__copy_mobile_2 {
        margin: 1.5rem 0;
    }

    .cta__copy_mobile_1, .cta__copy_mobile_2, .button-mobile {
        @include mq(large) {
            display: none;
        }
    }

    .button-mobile {
        margin-bottom: 1.5rem;
    }

    .cta__copy_1_desktop {
        display: none;
        @include mq(large) {
            display: block;
        }
    }
    .container-lg {
        text-align: center;
        position: relative;

        @include mq(large) {
            text-align: left;
            padding-bottom: 4rem;
        }
    }

    .gradient {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        height: 15rem;
        bottom: 2rem;
        background: linear-gradient(180deg, $primary-text 0.36%, rgba(3, 196, 197, 0.00) 99.64%);
        @include mq(large) {
            height:8rem;
        }
        @include mq(xlarge) {
            height: 14vh;
        }
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 500px;
 
        @include mq(large) {
            max-width: 42rem;
            width: 48%;
            float: left;
            margin-right: 8rem;
            -webkit-shape-outside: circle();
            shape-outside: circle();
            background: none;
            border: none;
        }
    }
}

.testimonials {
    margin-top: 4rem;

    .container {
        max-width: 329px;

        @include mq(medium) {
            max-width: 1040px;
        }
    }

    @include mq(medium) {
        margin-top: 6rem;
    }

    img {
        width: 100%;
        height: auto;
    }

    &__grid {
        display: grid;
        align-items: start;
        gap: 2rem;
        @include mq(medium) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 2.5rem;
        }
        @include mq(large) {
            gap: 5rem;
        }
    }

    blockquote {
        margin: 0;
        position: relative;
    }

    &__wrapper {
        background-color: $primary-text;
        border-radius: 42px;
        padding: 0.75rem;
        @include mq(medium) {
            border-radius: 76px;
            padding: 1.375rem;
            
        }
    }

    &__copy {
        color: white;
        border: solid 3px white;
        font-size: 1.125rem;
        background-color: $primary-text;
        border-radius: 34px;
        line-height: 1.6;
        text-align: center;
        padding: 1.75rem 1.25rem;
        @include mq(medium) {
            font-size: 1.875rem;
            border-radius: 60px;
            border-width: 5px;
            padding: 2.5rem 2.25rem;
        }
    }

    &__name {
        color: $primary-text;
        font-size: 0.9375rem;
        font-weight: 600;
        text-decoration: underline;
        @include mq(medium) {
            font-size: 1.875rem;
        }
    }

    &__title {
        font-size: 0.9375rem;
        font-weight: 600;
        margin-top: 0.4em;
        @include mq(medium) {
            font-size: 1.875rem;
        }
    }

    .even {
        text-align: right;
    }

}

.analytics {
    margin-top: 4rem;
    @include mq(medium) {
        margin-top: 6rem;
    }
    &__grid {
        gap: 1.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mq(medium) {
            flex-direction: row;
            gap: 2.5rem;
        }
        @include mq(xlarge) {
            gap: 3.5rem;
        }
    }

    &__img {
        margin: 0 -20px;
        @include mq(medium) {
            order: -1;
            flex: 1 1 0%;
            margin: 0;
        }
    }

    hr {
        border-top: solid 2px #CDCDCD;
        margin: 3.375rem 0 1rem;
        @include mq(medium) {
            display: none;
        }
    }

    &__copy {
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 600;
        width: 100%;
        @include mq(medium) {
            width: 42%;
        }
        @include mq(xlarge) {
            width: 38%;
        }
        strong {
            color: $primary-text;
            font-weight: 500;
        }
        a {
            color: inherit;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    &__heading {
        color: $primary-text;
        font-size: 1.875rem;
        line-height: 1.2;
        margin-bottom: 0;
        text-align: right;
        @include mq(medium) {
            text-align: left;
        }
        @include mq(large) {
            font-size: 2.25rem;
        }
        @include mq(xlarge) {
            font-size: 2.75rem;
            line-height: 1.1;
        }
    }

    &__lead {
        color: $primary-text;
        text-align: center;
        margin-top: 0;
        @include mq(medium) {
            text-align: left;
        }
        @include mq(large) {
            font-size: 1.25rem;
        }
        @include mq(xlarge) {
            font-size: 1.25rem;
            line-height: 1.4;
        }
    }

    &__copy_1 {
        margin-bottom: 0;
        @include mq(medium) {
            text-align: right;
        }
        @include mq(large) {
            font-size: 1.25rem;
        }
        @include mq(xlarge) {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4;
        }
    }

    blockquote {
        margin: 0.5rem 0;
        padding: 2.5rem 0;
        position: relative;
        color: #A6A6A6;
        text-align: center;
        font-size: 12.5px;
        font-style: italic;
        font-weight: 600;
        line-height: 1.9;
        span {
            position: relative;
            max-width: 20rem;
            margin: 0 auto;
            display: block;
        }
        @include mq(large) {
            font-size: 1rem;
        }
        @include mq(xlarge) {
            font-size: 1.125rem;
            line-height: 1.7;
            margin: 2rem 0;
            span {
                max-width: 30rem;
            }
        }
    }

    .top {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .bottom {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.avatar {
    margin-top: 4rem;
    @include mq(medium) {
        margin-top: 6rem;
    }
    .button {
        margin-top: -2.625rem;
        display: inline-block;
        font-size: 1.375rem;
        padding: 15px 56px;
        line-height: 1.2;
        max-width: 22rem;

    }
    &__grid {
        gap: 1.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mq(medium) {
            flex-direction: row;
            gap: 2.5rem;
        }
        @include mq(xlarge) {
            gap: 3.5rem;
        }
    }

    &__img {
        margin: 0 -20px;
        text-align: center;
        @include mq(medium) {
            flex: 1 1 0%;
            margin: 0;
        }
    }

    hr {
        border-top: solid 2px #CDCDCD;
        margin: 3.375rem 0 1rem;
        @include mq(medium) {
            display: none;
        }
    }

    &__copy {
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 600;
        width: 100%;
        @include mq(medium) {
            width: 42%;
        }
        @include mq(xlarge) {
            width: 38%;
        }
        strong {
            color: $primary-text;
            font-weight: 500;
        }
        a {
            color: inherit;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    &__heading {
        color: $primary-text;
        font-size: 1.875rem;
        line-height: 1.2;
        margin-bottom: 0;
        text-align: center;
        @include mq(medium) {
            text-align: left;
        }
        @include mq(large) {
            font-size: 2.25rem;
        }
        @include mq(xlarge) {
            font-size: 2.75rem;
            line-height: 1.1;
        }
    }

    &__lead {
        color: $primary-text;
        text-align: center;
        margin-top: 0;
        font-size: 1.5rem;
        @include mq(medium) {
            text-align: left;
            margin-top: 0.125em;
        }
        @include mq(large) {
            font-size: 1.25rem;
        }
        @include mq(xlarge) {
            line-height: 1.4;
        }
    }

    &__copy_1 {
        margin-bottom: 0;
        text-align: center;
        @include mq(medium) {
            text-align: left;
        }
        @include mq(large) {
            font-size: 1.25rem;
        }
        @include mq(xlarge) {
            font-weight: 500;
            font-size: 1.3125rem;
            line-height: 1.4;
        }
    }

    blockquote {
        margin: 0.5rem 0;
        padding: 2.5rem 0;
        position: relative;
        color: #A6A6A6;
        text-align: center;
        font-size: 12.5px;
        font-style: italic;
        font-weight: 600;
        line-height: 1.9;
        span {
            position: relative;
            max-width: 20rem;
            margin: 0 auto;
            display: block;
        }
        @include mq(large) {
            font-size: 1rem;
        }
        @include mq(xlarge) {
            font-size: 1.125rem;
            line-height: 1.7;
            margin: 2rem 0;
            span {
                max-width: 30rem;
            }
        }
    }

    .top {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .bottom {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.size_copy_mobile {
    text-align: center;
    @include mq(medium) {
        display: none;
    }
}

.size {
    margin-top: 4rem;
    @include mq(medium) {
        margin-top: 6rem;
    }
    .container {
        max-width: 1040px;
    }
    .button {
        margin-top: -2rem;
        display: inline-block;
        font-size: 1.375rem;
        padding: 15px 56px;
        line-height: 1.2;
        max-width: 28rem;
    }
    &__grid {
        gap: 1.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mq(medium) {
            flex-direction: row;
            gap: 2.5rem;
        }
        @include mq(xlarge) {
            gap: 8rem;
        }
    }

    &__img {
        margin: 0 -20px;
        text-align: center;
        padding: 0 1rem;
        @include mq(medium) {
            flex: 1 1 0%;
            margin: 0;
            padding: 0;
        }
    }

    &__copy {
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 600;
        width: 100%;
        display: none;
        text-align: center;
        @include mq(medium) {
            display: block;
            width: 50%;
        }
        strong {
            color: $primary-text;
            font-weight: 500;
        }
        a {
            color: inherit;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    &__heading {
        color: $primary-text;
        font-size: 1.875rem;
        line-height: 1.2;
        margin-bottom: 0;
        @include mq(medium) {
            font-size: 2.25rem;
        }
        @include mq(large) {
            font-size: 2.75rem;
            line-height: 1.1;
        }
    }

    &__lead {
        color: $primary-text;
        // text-align: center;
        margin-top: 0;
        
        @include mq(medium) {
            margin-top: 0.125em;
            font-size: 1.5rem;
        }
        @include mq(large) {
            font-size: 1.25rem;
        }
        @include mq(large) {
            font-size: 1.5rem;
            line-height: 1.4;
        }
    }

    &__copy_1 {
        margin-bottom: 0;
        @include mq(large) {
            font-size: 1.25rem;
        }
        @include mq(large) {
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.4;
        }
    }

    &__copy_2 {
        margin-bottom: 0;
        @include mq(large) {
            font-size: 1.25rem;
        }
        @include mq(large) {
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.4;
        }
        
    }
}

.meta {
    margin-top: 4rem;
    @include mq(medium) {
        margin-top: 6rem;
    }
    &__grid {
        gap: 1.25rem;
        display: flex;
        flex-direction: column;
        @include mq(medium) {
            flex-direction: row;
            gap: 2.5rem;
            align-items: center;

        }
        @include mq(xlarge) {
            gap: 3.5rem;
        }
    }

    &__img {
        margin: 0 -20px;
        @include mq(medium) {
            flex: 1 1 0%;
        }
    }

    hr {
        border-top: solid 2px #CDCDCD;
        margin: 3.375rem 0 1rem;
        @include mq(medium) {
            display: none;
        }
    }

    &__copy {
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 600;
        width: 100%;
        @include mq(medium) {
            width: 42%;
        }
        @include mq(xlarge) {
            width: 38%;
        }
        strong {
            color: $primary-text;
            font-weight: 500;
        }
        a {
            color: inherit;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    &__heading {
        color: $primary-text;
        font-size: 1.875rem;
        line-height: 1.2;
        margin-bottom: 0;
        text-align: center;
        @include mq(medium) {
            text-align: left;
        }
        @include mq(large) {
            font-size: 2.25rem;
        }
        @include mq(xlarge) {
            font-size: 2.75rem;
            line-height: 1.1;
        }
    }

    &__lead {
        color: $primary-text;
        text-align: center;
        margin-top: 0;
        @include mq(medium) {
            text-align: left;
            margin-top: 0.125em;
        }
        @include mq(large) {
            font-size: 1.25rem;
        }
        @include mq(xlarge) {
            font-size: 1.25rem;
            line-height: 1.4;
        }
    }

    &__copy_1 {
        margin-bottom: 0;
        text-align: right;
        @include mq(medium) {
            text-align: left;
        }
        @include mq(large) {
            font-size: 1.25rem;
        }
        @include mq(xlarge) {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4;
        }
    }

    &__copy_2 {
        @include mq(medium) {
            text-align: right;
        }
        @include mq(large) {
            font-size: 1.25rem;
        }
        @include mq(xlarge) {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4;
        }
        
    }

    blockquote {
        margin: 0.5rem 0;
        padding: 2.5rem 0;
        position: relative;
        color: #A6A6A6;
        text-align: center;
        font-size: 12.5px;
        font-style: italic;
        font-weight: 600;
        line-height: 1.9;
        span {
            position: relative;
            max-width: 20rem;
            margin: 0 auto;
            display: block;
        }
        @include mq(large) {
            font-size: 1rem;
        }
        @include mq(xlarge) {
            font-size: 1.125rem;
            line-height: 1.7;
            margin: 2rem 0;
            span {
                max-width: 30rem;
            }
        }
    }

    .top {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .bottom {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .wrap {
        width: 100%;
        min-width: 280px;
        aspect-ratio: 560/315;
        margin: auto;
        position: relative;
            
        iframe{
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
        }
    }
}

