section {
    margin-top: 2rem;
}

.es-logo {
    padding: 60px 0 5px 0;
    margin-top: 0;
    &__content  {
        text-align:center;
    }
    &__img {
        width:200px;
        height:auto;
        @include mq(medium) {
            width:300px;
        }
    }
    @include mq(medium) {
        padding-top: 75px;
    }
}

$heightSmall: 459px;
$heightMedium: 650px;
$heightLarge: 482px;
.es-hero {
    margin-top: 0;
    // width: 100%;
    display: block;
    height: $heightSmall;
    @include mq(medium) {
        height: $heightMedium;
    }
    @include mq(large) {
        height: $heightLarge;
    }

    .container {
         padding:0px;
    }

    &__bg {
        position: absolute;
        width: 100%;
        max-width: $max-width;
        height: $heightSmall;
        background-image: url($esHeroMobile);
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #eee;

        @include mq(medium) {
            height:$heightMedium;
            background-image: url($esHeroTablet);
            background-position: top right;
            background-size: auto $heightMedium;
        }

        @include mq(large) {
            height: $heightLarge;
            background-image: url($esHeroDesktop);
            background-position: center center;
            background-size: contain;
        }
    }

    &__bg::before{
        background-image: linear-gradient(rgba(255,255,255,0) 40%, rgba(255,255,255,1) 90%);
        height:100%;
        border-radius: 16px;
        position: absolute;
        display: block;
        left:0;
        right:0;
        bottom:0;
        top: 0;
        content: "";    
        @include mq(medium) {
            display:none;
        }
    }

    &__content {
        display:block;
        position:absolute;
        height: $heightSmall;
        width:100%;
        @include mq(medium) {
            height: $heightMedium;
        }
        @include mq(large) {
            height: $heightLarge;
        }
    }

    &__content-flex {
        height:100%;
        max-width: $max-width;
        display: flex;
        flex-direction: column;
        justify-content:flex-end;
        align-items: center;
        @include mq(medium) {
            justify-content:center;
            padding-left: 7%;
            align-items: flex-start;
        }
        @include mq(large) {
            justify-content:center;
            padding-left: 5%;
            align-items: center;
        }
    }

    h2 {
        font-size: $medium;
        font-weight: $semi-bold;
        text-align: center;                
        color: #777;
        margin: 10px 0px;
        width: 70%;
        @include mq(medium) {
            width: 40%;
            font-size: $large;
        }
        @include mq(large) {
            width: 300px;

        }
    }
}

.es-cta {
    font-size: $extra-small;
    @include mq(medium) {
        font-size: $small;
    }
    @include mq(large) {
        font-size: $small-secondary;
    }

    p {
        text-align: center;
    }

    p emph {
        color: $orange;
    }

    &__p1 {
        font-weight: $semi-bold;
    }

    h4 {
        text-align: center;
    }

    &__buttoncontainer {
        margin:1.6em 0;
    }
}

.es-benefits-video {
    margin: 1rem 0 0 0;
    @include mq(medium){
        // padding: 2rem 0;
    }
}

.video__wrap {
    width: 100%;
    min-width: 280px;
    max-width: 560px;
    aspect-ratio: 560/315;
    margin: auto;
    position: relative;
        
    iframe{
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        border-radius: 20px;
    }

}

.es-images-carousel {
    margin-top:2rem;
}

.splide__slide img {
    width:100%;
    height:auto;
}

.es-process {
    margin-top: 2rem;
    &__heading {
        text-align:center;
        margin: 10px 5px;
        font-size: $medium;

        @include mq(medium) {
            font-size: $medium-nav;
        }

        @include mq(large) {
            font-size: $large;
        }
    }
    &__section {
        display:flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    &__s2 {
        flex-direction: row-reverse;
    }

    &__text-wrap {
        min-width: 300px;
        width: 50%;
        align-self: center;
    }
    
    &__text {
        text-align: center;
        font-size: $small;
        font-weight: $semi-bold;
        color: $grey;
        @include mq(medium) {
            font-size: $medium;
        }
        @include mq(large) {
            font-size: $medium-nav;
        }
    }

    &__img-wrap {
        width: 300px;
    }

    &__img {
        width: 100%;
    }
}
.es-demo-video {
    margin: 1rem 0 0 0;
    @include mq(medium) {
        margin: 3rem 0 0 0;
    }
}

.es-customers {
    font-size: $extra-small;
    @include mq(medium) {
        font-size: $small;
    }
    @include mq(large) {
        font-size: $medium;
    }

    &__content {
        background-color: $secondary-background-light;
        border: 1px solid $primary-border-light;
        border-radius: 20px;
        padding: 20px 0;
    }

    &__p0, &__p1 {
        margin-top:0;
        text-align: center;
        padding: 0 1em;
    }

    &__p2 {
        padding:  1em 0 1em 0;
        text-align:center;
    }
}

.es-cmp {
    margin-top: 2em;

    &__flex-wrap {
        display:flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    &__flex1 {
        width: 50%;
        min-width: 200px;
        max-width: 500px;
        display: flex;
        flex-flow: column nowrap;
        justify-content:center;
    }
    p, h3 {
        margin: 10px 0;
    }
    &__p1 {
        text-align: center;
        font-size: $medium;
        font-weight: $semi-bold;
        color: $secondary-text-dark;
        @include mq(medium) {
            font-size: $medium-nav;
        }
    }
    &__p2 {
        text-align: center;
        font-size: $small;
        font-weight: $secondary-semi-bold;
        color: $grey;
        @include mq(medium) {
            font-size: $medium;
        }
    }
    &__p3 {
        padding-top: 0.5em;
        text-align: center;
    }
    &__p4 {
        padding-top: 1em;
        text-align:center;
    }
}

.es-intro {
    margin-top: 1em;
    margin-bottom: 2em;
    p, h3 {
        margin: 10px 0;
    }

    &__section-marker {
        background-color: $secondary-background-light;
        border: 1px solid $primary-border-light;
        border-radius: 20px;
        padding: 10px;
    }

    &__flex-wrap1 {
        display:flex;
        flex-flow:row-reverse wrap;
        justify-content: space-evenly;
    }

    &__flex1-1 {
        width: 50%;
        min-width: 200px;
        max-width: 500px;
        display:flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

    &__p0 {
        text-align: center;
        font-size: $medium;
        font-weight: $semi-bold;
        color: $secondary-text-dark;
        @include mq(medium) {
            font-size: $medium-nav;
        }
    }

    &__p1 {
        text-align:center;
        color: $secondary-text-dark;
        font-size: $small;
        font-weight: $secondary-semi-bold;
        color: $grey;
        @include mq(medium) {
            font-size: $medium;
        }
    }
    &__p2 {
        text-align:center;
        padding-top:10px;
    }
    &__p3 {
        text-align:center;
    }

    &__flex-wrap2 {
        display:flex;
        flex-flow:row wrap;
        justify-content: space-evenly;
    }

    &__flex2-1 {
        width: 50%;
        min-width: 200px;
        max-width: 500px;
        display:flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

}

.es-features {
    &__table {
        // Center align 
        margin: 0 auto;

        caption {
            display:none;
        }

        tfoot {
            display:none;
        }
    }

    &__features-col {
        background-color: $aqua;
    }

    &__intro-col {
        background-color: $light-aqua;
    }

    &__ecoshot-col {
        background-color: $light-purple;
    }

    th, td {
        padding: 0.5em;
    }

    thead th, tfoot th  {
        font-variant:small-caps;
        font-size:$extra-small;
        font-weight:$secondary-semi-bold;
        @include mq(medium) {
            font-size:$small;
        }
        @include mq(large) {
            font-size:$small-secondary;
        }
    }

    &__table tr th:nth-child(1) {
        color:white;
    }

    &__table tbody tr th, &__table tbody tr td  {
        font-weight: $normal;
        text-align: center;
        font-size: $extra-small;
        @include mq(medium) {
            font-size:$small;
        }
        @include mq(large) {
            font-size:$small-secondary;
        }
    }

    &__unlimited {
        font-variant: small-caps;
    }

    &__cta-contact {
        color: black;
    }

    &__cta {
        padding-top: 1em;
        text-align: center;
    }
}