/**
 * Basic typography style for copy text
 */
body {
  font-family: $primary-font, sans-serif;
  font-style: normal;
  font-weight: $normal;
  position: relative;
}

strong {
  font-weight: $semi-bold;
}

.section-heading-primary{
  font-weight: $bold;
  font-size: $extra-large-mobile;
  line-height: 56px;
  text-transform: uppercase;
  color: $primary-text-white;
  @include mq(medium){
    font-size: $extra-large;
    line-height: 61px;
  }
}

.section-heading-secondary {
  font-weight: $bold;
  font-size: $large;
  line-height: 37px;
  text-transform: uppercase;
  text-align: center;
  color: $primary-text-dark;
}

.sub-heading-primary {
  font-weight: $bold;
  font-size: $medium;
  line-height: 25px;
  color: $primary-text-dark;
  @include mq(medium){
    font-size: $medium-nav;
  }
}

.sub-heading-secondary{
  font-weight: $bold;
  font-size: $medium;
  line-height: 36px;
  color: $primary-text-dark;
  @include mq(medium){
    font-size: $medium-nav;
  }
}

.primary-text {
  font-size: $extra-small;
  line-height: 24px;
  color: $secondary-text-dark;

  &--bold{
    font-weight: $semi-bold;
  }
}
