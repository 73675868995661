// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------


@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Regular.woff2') format('woff2'),
         url('fonts/Montserrat-Regular.woff') format('woff'),
         url('fonts/Montserrat-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Bold.woff2') format('woff2'),
         url('fonts/Montserrat-Bold.woff') format('woff'),
         url('fonts/Montserrat-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-SemiBold.woff2') format('woff2'),
         url('fonts/Montserrat-SemiBold.woff') format('woff'),
         url('fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Medium.woff2') format('woff2'),
         url('fonts/Montserrat-Medium.woff') format('woff'),
         url('fonts/Montserrat-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-LightItalic.woff2') format('woff2'),
         url('fonts/Montserrat-LightItalic.woff') format('woff'),
         url('fonts/Montserrat-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    font-display: swap;
}