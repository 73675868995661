.project-hero{
    padding: 4rem 0 2rem 0;

    &__content{
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__text-wrap{
        @include mq(medium){
            flex: 0 0 48%;
            padding-top: 30px;
        }
        @include mq(large){
            padding-top: 50px;
        }
    }

    &__image-wrap{
        margin-top: 20px;
        @include mq(medium){
            flex: 0 0 48%;
        }
    }

    &__heading{
        text-align: left;
    }

    &__text{
        @include mq(medium){
            width: 80%;
        }
        @include mq(large){
            width: 70%;
        }
    }

    &__image{
        width: 100%;
        border-radius: 16px;
    }
}

.project-tryon{
    padding: 1rem 0;
    @include mq(medium){
        padding: 3rem 0 2rem 0;
    }

    &__content{
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__image-wrap-desktop{
        display: none;
        @include mq(medium){
            display: flex;
            flex: 0 0 48%;
        }
    }

    &__image-desktop{
        width: 100%;
    }

    &__image-mobile{
        width: 100%;
        height: 100%;
    }

    &__description{
        @include mq(medium){
            flex: 0 0 48%;
        }
    }

    &__logo-wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }

    &__logo{
        width: 62px;
        height: 62px;
    }

    &__heading{
        font-weight: $normal;
        font-size: $medium;
        line-height: 36px;
        color: $primary-text-dark;
        @include mq(medium){
            font-size: $medium-nav;
        }
    }

    &__image-wrap-mobile{
        @include mq(medium){
            display: none;
        }
    }

    &__image-mobile{
        width: 100%;
    }
}

.project-cases{
    padding: 0 0 2rem 0;

    &__heading{
        text-transform: inherit;
    }

    &__cards{
        margin-top: 40px;
    }

    &__card{
        @include mq(medium){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__text-wrap{
        @include mq(medium){
            flex: 0 0 48%;
        }
    }

    &__card-heading{
        font-weight: $semi-bold;
        font-size: $medium-nav;
        line-height: 36px;
        color: $secondary-text-dark;
        margin-bottom: 0;
    }

    &__text{
        margin-top: 10px;
    }

    &__image-wrap{
        margin-bottom: 20px;
        @include mq(medium){
            flex: 0 0 48%;
        }
    }

    &__image{
        width: 100%;
    }
}