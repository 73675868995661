// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.button {
    font-family: $primary-font;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: $extra-small;
    line-height: 17px;
    color: $primary-text-white;
    background-color: $green;
    border: 1px solid $green-border;
    padding: 12px 30px;
    border-radius: 50px;
    text-decoration: none;

    &--lite {
        background-color: $grey;
        border-color: $grey;
    }

    &--secondary{
        background-color: $primary-background;
        color: $green;
        padding: 8px 15px;
        @include mq(small){
            padding: 8px 10px;
        }
    }

    @include mq(medium) {
        font-size: $small;
    }

    @include mq(large) {
        font-size: $small-secondary;
    }

    &:hover{
        text-decoration: none;
        cursor: pointer;
    }
}