.article{
    padding: 6rem 0 0 0;
    @include mq(medium){
        padding: 7rem 0 2rem 0;
    }

    &__wrap{
        @include mq(medium){
            max-width: 940px;
            width: 100%;
            margin: auto;
        }
    }

    &__heading{
        text-align: left;
    }

    &__featured-image-wrap{
        margin: 20px 0 0 0;
        height: 287px;
        @include mq(medium){
            height: 527px;
        }
    }

    &__featured-image{
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
    }

    &__hr{
        border: none;
        height: 1px;
        background-color: $article-border;
        margin-bottom: 20px;
        @include mq(medium){
            margin-top: 150px;
        }
    }
}

.article-content{

    *{
        font-size: $extra-small;
        line-height: 30px;
        color: $secondary-text-dark;
        
    }

    h2{
        font-weight: $semi-bold;
        font-size: 24px;
        line-height: 40px;
        color: $primary-text-dark;
    }
    h3{
        font-weight: $semi-bold;
        font-size: $medium;
    }

    p{
        margin-bottom: 32px;

        img{
            width: 90%;
            margin: auto;
        }
    }

    img{
        display: block;
        margin: 32px auto;
        border-radius: 16px;
        width: 100%;
        object-fit: cover;
        @include mq(medium){
            border-radius: 20px;
        }
    }
    a{
        font-weight: $semi-bold;
    }
    .youtube-video{
        margin: 32px auto;
        border-radius: 16px;
        @include mq(medium){
            border-radius: 20px;
        }
    }

    figure{
        max-width: 340px;
        width: 100%;
        margin: auto;
    }
}